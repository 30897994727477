import './Cards.sass';
import { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cardsActions, cardsSelector } from '$store/cards';
import { useTranslator } from '$hooks';
import { CardsSwiper } from './CardsSwiper/CardsSwiper';
import { Loader, LottieIcon } from '$shared/components';
import { Button, PanelHeader, Placeholder, Spinner } from '@vkontakte/vkui';
import { Icon56ErrorOutline } from '@vkontakte/icons';
import { FILTERS } from '$shared/constants/pages';
import { useRouter } from 'react-router5';
import bridge, { BannerAdLayoutType, BannerAdLocation } from '@vkontakte/vk-bridge';

let adsCalled = false;

export const Cards: FC = () => {
  const dispatch = useDispatch();
  let { loadingStatus, cards } = useSelector(cardsSelector);
  const t = useTranslator();
  const router = useRouter();

  const loadData = useCallback(() => {
    dispatch(cardsActions.loadCards());
  }, []);

  useEffect(() => {
    loadData();

    if (!adsCalled && window.isVK) {
      adsCalled = true;
      bridge.send('VKWebAppShowBannerAd', {
        banner_location: BannerAdLocation.BOTTOM,
        layout_type: BannerAdLayoutType.RESIZE,
        can_close: true,
      });
    }
  }, []);

  const handleEdit = useCallback(() => {
    router.navigate(FILTERS);
  }, []);

  function renderContent() {
    if (loadingStatus === 'loading' && cards.length === 0) {
      return (
        <Placeholder stretched>
          <Spinner />
        </Placeholder>
      );
    }

    if (loadingStatus === 'failed') {
      return (
        <Placeholder
          icon={<Icon56ErrorOutline />}
          header={t('error_title')}
          action={<Button onClick={loadData}>{t('error_button')}</Button>}
        >
          {t('error_text')}
        </Placeholder>
      );
    }

    if (!cards.length) {
      return (
        <Placeholder
          icon={<LottieIcon name="duck_not_found" loop />}
          stretched
          action={<Button onClick={handleEdit}>{t('cards_empty_btn')}</Button>}
          header={t('cards_empty_title')}
        >
          {t('cards_empty_text')}
        </Placeholder>
      );
    }

    return <CardsSwiper cards={cards} />;
  }

  return (
    <>
      {window.isVK && <PanelHeader>{t('cards_title')}</PanelHeader>}
      {renderContent()}
    </>
  );
};
